<template>
  <Breadcrumb />
  <div class="d-flex justify-content-end">
    <router-link
      :to="{
        name: 'LocalizationNew',
        params: {},
      }"
      class="btn btn-success btn-action-new-page"
    >
      <i class="bi bi-plus"></i>
      {{
        $t(
          "Localizations.NewButton",
          {},
          { locale: this.$store.state.activeLang }
        )
      }}
    </router-link>
  </div>
  <Grid
    :settings="gridSettings"
    :columns="gridColumns"
    @onTranslationButtonClick="onTranslationButtonClick"
  />
  <TranslationModal
    :keyTypeId="keyTypeId"
    :parentPublicId="parentPublicId"
    :formulaName="formulaName"
    :keyName="keyName"
    :keyItemPublicId="keyItemPublicId"
    :keyPluralName="keyPluralName"
  />
</template>
<script>
import { showModal } from "@/core/helpers/dom";
export default {
  name: "LocalizationKey",
  data() {
    return {
      keyTypeId: 0,
      parentPublicId: "",
      formulaName: "",
      keyName: "",
      keyPluralName: "",
      translateRecordId: null,
      translateFormulaName: null,
      gridSettings: {
        action: "LocalizationKey",
        requestUrl: String.format("/Brs-LocalizationList"),
        routeRequiredParameters: [
          {
            key: "localizationId",
            value: "publicId",
            isRouteParameter: false,
          },
        ],
        isGatewayRequest: true,
        allowSearchPanel: true,
        allowExcelExport: false,
        allowDragAndDrop: false,
        allowPaging: true,
        allowDeleting: true,
        deleteActionUrl: "/Brs-LocalizationDelete",
        autoOrderProcess: true,
        buttons: [
          {
            name: "edit",
            cssClass: "btn-warning",
            iconClass: "bi-pencil-square",
            routeButton: true,
            routeObj: {
              name: "LocalizationEdit",
              params: {
                localizationId: "",
              },
            },
          },
          {
            name: "translate",
            cssClass: "btn-primary btn-light translation",
            iconClass: "bi bi-translate",
            routeButton: false,
            emitMethodName: "onTranslationButtonClick",
            attributes: {
              "data-bs-toggle": "modal",
              "data-bs-target": "#translationModal",
            },
            iconAttributes: {
              "data-bs-toggle": "tooltip",
              "data-bs-placement": "top",
              title: this.$t(
                "Components.TranslationModal.Title",
                {},
                { locale: this.$store.state.activeLang }
              ),
            },
          },
        ],
      },
      gridColumns: [
        {
          text: this.$t(
            "CustomButtons.Name",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "name",
          type: "string",
          visible: true,
          width: 200,
          template: "",
          format: "",
          textAlign: "",
        },
        {
          text: this.$t(
            "BaseModelFields.PluralName",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "pluralName",
          type: "text",
          visible: false,
          width: 0,
          template: "",
          format: "",
          textAlign: "",
        },
        {
          text: this.$t(
            "CustomButtons.FormulaName",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "formulaName",
          type: "string",
          visible: true,
          width: 200,
          template: "",
          format: "",
          textAlign: "",
        },
        {
          text: this.$t(
            "Localizations.KeyType",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "localizationKeyItemType",
          type: "string",
          visible: true,
          width: 200,
          template: "",
          format: "",
          textAlign: "",
        },
        {
          text: this.$t(
            "BaseModelFields.IsActive",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "isActive",
          type: "boolean",
          visible: true,
          width: 0,
          template: "",
          format: "",
          textAlign: "",
        },
        {
          text: this.$t(
            "BaseModelFields.CreatedBy",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "createdByName",
          type: "string",
          visible: false,
          width: 0,
          template: "",
          format: "",
          textAlign: "",
        },
        {
          text: this.$t(
            "BaseModelFields.CreatedAt",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "createdAt",
          type: "datetime",
          visible: false,
          width: 0,
          template: "",
          format: this.$store.getters._dateTimeFormat,
          textAlign: "",
        },
        {
          text: this.$t(
            "BaseModelFields.UpdatedBy",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "updatedByName",
          type: "string",
          visible: false,
          width: 0,
          template: "",
          format: "",
          textAlign: "",
        },
        {
          text: this.$t(
            "BaseModelFields.UpdatedAt",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "updatedAt",
          type: "datetime",
          visible: false,
          width: 0,
          template: "",
          format: this.$store.getters._dateTimeFormat,
          textAlign: "",
        },
      ],
      translateRow: {},
    };
  },
  methods: {
    onTranslationButtonClick(rowData) {
      this.keyItemPublicId = rowData.publicId;
      this.keyTypeId = rowData.localizationKeyItemTypeId;
      this.formulaName = rowData.formulaName;
      this.keyName = rowData.name;
      this.parentPublicId = rowData.keyItemParentPublicId;
      // showModal(document.getElementById("translationModal"));
    },
  },
  mounted() {
    // if (this.$store.getters._isMultiLanguage) {
    //   this.gridSettings.buttons.push({
    //     name: "translation",
    //     cssClass: "btn-light translation",
    //     iconClass: "bi bi-translate",
    //     routeButton: false,
    //     emitMethodName: "onTranslationButtonClick",
    //   });
    // }
  },
};
</script>
